<template>
    <footer>
        <div class="footer">
            <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-6 col-12 footer-list">
                    <div class="logofooter">
                        <img src="@/assets/img/logo_2.png" alt="">
                    </div>
                    <div class="follow mt-3">
                        {{ $t('follow_us') }}
                        <a :href="settings?.instagram">
                            <img src="@/assets/img/002-instagram.svg" alt="" class="social-icon mx-2">
                        </a>
                        <a :href="settings?.facebook">
                            <img src="@/assets/img/003-facebook.svg" alt="" class="social-icon">
                        </a>
                    </div>
                    <div class="visa mt-2">
                        <a href="">
                            <img src="@/assets/img/wallet.svg" alt="">
                        </a>
                        <a href="">
                            <img src="@/assets/img/knet.svg" alt="">
                        </a>
                        <a href="">
                            <img src="@/assets/img/masterCard.svg" alt="">
                        </a>
                        <a href="">
                            <img src="@/assets/img/visa.svg" alt="">
                        </a>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 col-12 connect footer-list">
                    <h5>{{ $t('nav.contact-us') }}</h5>
                    <a :href="`tel:${settings.phone}`">
                        <i class="fas fa-phone"></i>
                        <!--                        <span>{{ settings.phone }}</span>-->

                        <span>505050505</span>
                    </a>
                    <a :href="`tel:${settings.phone}`">
                        <i class="fas fa-mobile-alt"></i>
                        <!--                        <span>{{ settings.phone }}</span>-->
                        <span>96597687419</span>
                    </a>
                    <a href="mailto:petpoint.co@gmail.com">
                        <i class="far fa-envelope"></i>
                        <!--                        <span>{{ settings.email }}</span>-->
                        <span>petpoint.co@gmail.com</span>
                    </a>
                    <a href="">
                        <i class="fas fa-map-marker-alt"></i>
                        <span>Street 26 block 1 Building 224,</span>
                    </a>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6 col-12  footer-list">
                    <h5>{{ $t('nav.help') }}</h5>
                    <ul>
                        <li v-if="settings.about">
                            <router-link to="/about">
                                {{ $t('nav.about') }}
                            </router-link>
                        </li>
                        <li v-if="settings.support">
                            <router-link to="/support">
                                {{ $t('nav.help') }}
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/contact-us">
                                {{ $t('nav.contact-us') }}
                            </router-link>
                        </li>
                        <li v-if="settings.terms">
                            <router-link to="/terms-and-conditions">
                                {{ $t('nav.terms-of-service') }}
                            </router-link>
                        </li>
                    </ul>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6 col-12  footer-list">
                    <h5>{{ $t('nav.all-brands') }}</h5>
                    <ul>
                        <li v-for="brand in categories" :key="brand.id">
                            <router-link :to="{name: 'Brand' , params: {id: brand.id}}">
                                {{ brand.name }}
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>

<script setup>

import {useStore} from "vuex";
import {computed} from "vue";

const store = useStore();
const categories = computed(() => store.getters.brands.slice(0, 4))
const settings = computed(()=>store.getters.settings)
</script>

<style scoped>

</style>