<template>
    <Header/>
    <main>
        <slot name="page-content"></slot>
    </main>
  <Footer/>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  name: "BaseLayout",
  components: {Footer, Header}
}
</script>

<style scoped>

</style>