
import {logOut} from "@/services/auth";
import {mapGetters} from "vuex";
import {updateProfile} from "../services/auth";
import store from "@/store";

export default {
    name: "Header",
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    data() {
        return {
            Categories: [],
            switcher: false,
            current_country: {},
            loading: false

        }
    },
    computed: {
        // map `this.user` to `this.$store.getters.user`
        ...mapGetters({
            user: "user",
            countries: "countries",
            favItemsCount: "favItemsCount",
            cartItemsCount: "cartItemsCount",
            currency: "getCurrency"
        }),

    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    mounted() {
        this.getCountries();
        this.getCategories();
    },
    methods: {
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        toggleDropDown(e) {
            e.stopPropagation();
            this.switcher = !this.switcher
        },
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        switchLang(lang) {
            this.$i18n.locale = lang
            document.dir = lang === "en" ? 'ltr' : 'rtl';
            localStorage.setItem('locale', lang)
            store.dispatch('fetchSettings',lang)
            this.switcher = false;
        },
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        getCountries() {
            this.loading = true;
             if (this.$store.getters.user.loggedIn) {
                let userCountry = this.$store.getters.user.data.Country;
                this.current_country = userCountry ? userCountry : this.$store.getters.countries[0]
                this.loading = false;
            }
        },
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        getCategories() {
            this.Categories = this.$store.getters.brands.slice(0, 4);
        },
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        handleChangeCountry(country) {
            this.loading = true;
            this.current_country = country;
            store.dispatch('changeCurrency',country)
            store.dispatch('fetchProducts', country.id)

            // updateProfile({...this.user.data, country_id: country.id, city_id: null}).then(res=>{
            //     this.loading = false;
            //     store.dispatch("fetchUser", res.data.User);
            // })
        },

        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        handleLogout() {
            logOut().then(() => {
                localStorage.removeItem('access_token')
                this.$router.push('/login')
                this.$store.dispatch("fetchUser");
                this.$store.dispatch("resetAll");
            })
                .catch(e => console.error(e))
        }
    }
}
